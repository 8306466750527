import * as React from 'react';
import useOnclickOutside from "react-cool-onclickoutside";
import {
    InputElement,
    DocumentationContentContainer,
    DocumentationInputContainer,
    Text18XS14Bold,
    DocumentationLink,
    FilterContainer,
    FilterContainerLeft,
    FilterContainerRight,
    LeftHeader,
    LeftCheckbox,
    CheckboxText,
    RightHeader,
    RightDescr,
    RightTitleList,
    FilterClick,
    FilterClickHeader,
    FilterCountCheckbox, TitleListCompany, RightTitleListElement,
} from "./style";
import {Text14, Text14Gray} from "../../../styles/fonts";
import { ContentTop24, ContentTop8, ContentTopStart40, FlexRowSpaceBetween} from "../../../styles/content";
import {Filter, Search} from "../../../icons/icons";
import {DocumentButton, FlexGap12px, TextAlignEndXS} from "../style";
import {Doc} from '../Support'
import {useEffect, useState} from "react";
import settings from '../../../config/settings.json';
import documentation from '../../../config/documentation.json';
import '../../../styles/chcekbox.css';

export interface IDocumentation {
    filterView:boolean
    close:()=>void
}

const allSettingNamesSetting = settings.map(i => i.data.name)
let allSettingNamesDocumentation = (documentation.map(i => i.data.docs.map(el=>el.subcategory))).flat().filter((value, index, array) => array.indexOf(value) === index)

export const Documentation = ({filterView, close}:IDocumentation) => {

    const [filterOpen,setFilterOpen]=useState<boolean>(false);
    const [windowWidth,setWindowWidth]=useState<number>(window.innerWidth)
    const [selectedSetting, setSelectedSetting] = useState<string[]>(filterView ? [] : allSettingNamesSetting)
    const [selectedDocumentation, setSelectedDocumentation] = useState<string[]>(filterView ? allSettingNamesDocumentation : [])
    const [count,setCount]=useState(0)
    const [inputSearch,setInputSearch]=useState('')
    const [newSettings, setNewSettings]= useState(settings);
    const [newDocumentation, setNewDocumentation]= useState(documentation);
    const selectedSettingsExamples = newSettings.filter(i => selectedSetting.includes(i.data.name))

    const settingsExampleSatisfiesSearch = selectedSettingsExamples.some(i => i.data.docs.some((item )=> item?.name.toLowerCase().includes(inputSearch.toLowerCase())))

    const ref = useOnclickOutside(() => {
        if (filterOpen) {
            setFilterOpen(false)
        }
    });

    useEffect(()=>{
        window.addEventListener('resize', ()=>setWindowWidth(window.innerWidth))
        return () => {
            window.removeEventListener('resize', ()=>setWindowWidth(window.innerWidth))
        }
    }, [windowWidth])

    useEffect(() => {
        setCount(selectedSetting.length+selectedDocumentation.length)

    }, [selectedSetting,selectedDocumentation]);

    return (
        <>
            <DocumentationContentContainer>
                <DocumentationLink>
                    <Text14Gray style={{cursor:'pointer'}} onClick={close}>Поддержка </Text14Gray>
                    <Text14>/ Документация</Text14>
                </DocumentationLink>
                <ContentTopStart40>
                    <Text18XS14Bold>
                        Поиск
                    </Text18XS14Bold>
                    <ContentTop8>
                        <DocumentationInputContainer>
                            <Search />
                            <InputElement
                                value={inputSearch}
                                onChange={(e)=>setInputSearch(e.target.value)}
                                placeholder={'Введите название документа'}
                            />
                        </DocumentationInputContainer>
                    </ContentTop8>
                </ContentTopStart40>
                <FilterContainer>
                    <FilterClick  ref={ref} onClick={()=>setFilterOpen(!filterOpen)}>
                        <Filter/>
                        <FilterClickHeader>Фильтр</FilterClickHeader>
                        <FilterCountCheckbox>{count}</FilterCountCheckbox>
                    </FilterClick>
                    {(windowWidth > 767 || (windowWidth<=767 && filterOpen)) &&
                        <FilterContainerLeft ref={ref}>
                            <LeftHeader>Документация</LeftHeader>
                            {allSettingNamesDocumentation.map((document, index) => {
                                return <LeftCheckbox key={index}>
                                    <input style={{marginBottom: 'auto',minWidth:'20px'}} className={'checkboxElement'} type="checkbox" checked={selectedDocumentation.includes(document)} onChange={()=> {
                                        if (selectedDocumentation.includes(document)) {
                                            setSelectedDocumentation(selectedDocumentation.filter(i => i !== document))
                                        } else {
                                            setSelectedDocumentation([...selectedDocumentation, document])
                                        }
                                    }}/>
                                    <CheckboxText>{document}</CheckboxText>
                                </LeftCheckbox>
                            })}
                            <LeftHeader>Примеры настройки</LeftHeader>
                            <LeftCheckbox>
                                <input style={{marginBottom: 'auto',minWidth:'20px'}} className={'checkboxElement'} type="checkbox" checked={(selectedSetting.length ?? 0) === (settings.length ?? 0)} onChange={()=> {
                                    if (selectedSetting.length === settings.length) {
                                        setSelectedSetting([])
                                    } else {
                                        setSelectedSetting(allSettingNamesSetting)
                                    }
                                }}/>
                                <CheckboxText>Выбрать все</CheckboxText>
                            </LeftCheckbox>
                            {settings.map((setting, index) => {
                                return <LeftCheckbox key={index}>
                                    <input style={{marginBottom: 'auto',minWidth:'20px'}} className={'checkboxElement'} type="checkbox" checked={selectedSetting.includes(setting.data.name)} onChange={()=> {
                                        if (selectedSetting.includes(setting.data.name)) {
                                            setSelectedSetting(selectedSetting.filter(i => i !== setting.data.name))
                                        } else {
                                            setSelectedSetting([...selectedSetting, setting.data.name])
                                        }
                                    }}/>
                                    <CheckboxText>{setting.data.name}</CheckboxText>
                                </LeftCheckbox>
                            })}
                        </FilterContainerLeft>}
                    <FilterContainerRight>
                        <ContentTop24>
                            {newDocumentation.map((doc) => {
                                const filtered = doc.data.docs.filter(i => selectedDocumentation.includes(i.subcategory))
                                if (filtered.length === 0 ) {
                                    return null
                                }

                                const childSatisfySearch = filtered.some(item => item.docs.some((item) => item?.name.toLowerCase().includes(inputSearch.toLowerCase())))

                                if (!childSatisfySearch) {
                                    return null
                                }

                                return  <>
                                    <RightHeader>{doc.data.name} {doc.data.version}</RightHeader>
                                    {/*<RightDescr>Дата релиза: 13.01.2022 19:00</RightDescr>*/}
                                    {filtered.map((item) => {
                                        const filtered = item.docs.filter((item) => item?.name.toLowerCase().includes(inputSearch.toLowerCase()))

                                        return <>
                                            {filtered.length > 0 && <RightTitleList>{item.subcategory}</RightTitleList>}
                                            {filtered.map((i) => (
                                                <RightTitleListElement key={i.id}>
                                                    <DocumentButton
                                                        rel={'noreferrer'}
                                                        target={"_blank"}
                                                        href={i.url}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        {i.name}
                                                    </DocumentButton>
                                                    <TextAlignEndXS style={{opacity: '0.5'}}>
                                                        {i.date}
                                                    </TextAlignEndXS>
                                                </RightTitleListElement>
                                            ))}
                                        </>
                                    })}
                                </>
                            })}
                        </ContentTop24>

                        {settingsExampleSatisfiesSearch && <>
                            <RightTitleList>Примеры настройки</RightTitleList>
                            <ContentTop24>
                                <FlexGap12px>
                                    {selectedSettingsExamples.map((doc: any, index) => {
                                        const filtered = doc.data.docs.filter((item: Doc )=> item?.name.toLowerCase().includes(inputSearch.toLowerCase()))
                                        return <>
                                            {filtered.length > 0 && <TitleListCompany key={index}>{doc.data.name}</TitleListCompany>}
                                            {filtered.map((item:any)=>(
                                                <FlexRowSpaceBetween key={item.id}>
                                                    <DocumentButton
                                                        rel={'noreferrer'}
                                                        target={"_blank"}
                                                        href={item.url}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        {item?.name}
                                                    </DocumentButton>
                                                    <TextAlignEndXS style={{opacity: '0.5'}}>
                                                        {item.date}
                                                    </TextAlignEndXS>
                                                </FlexRowSpaceBetween>
                                            ))}
                                        </>
                                    })}
                                </FlexGap12px>
                            </ContentTop24>
                        </>}

                    </FilterContainerRight>
                </FilterContainer>
            </DocumentationContentContainer>
        </>
    );
};
