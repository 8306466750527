import React from 'react'
import {NavbarBackground, NavbarBtn, NavbarItem} from "../../styles/content";
import {Theme} from "../../styles/Theme";
import {Text16lh24px, Text18lh28} from "../../styles/fonts";
import {DefaultBtn} from "../../styles/button";
import {scrollTo} from "../../utils/scrollTo";
import {ScrollToEnum, TabEnum} from "../../enum";

interface NavbarProps {
    onClose: () => void
    selectedTab: TabEnum

    onTabChange(tab: TabEnum): void
}

export const Navbar = ({onClose, onTabChange, selectedTab}: NavbarProps) => {
    return (
        <NavbarBackground>
            <NavbarItem
                        onClick={() => {
                            onClose()
                            onTabChange(TabEnum.main)
                            scrollTo(ScrollToEnum.intro)
                        }}>
                <Text16lh24px color={selectedTab === TabEnum.main ? Theme.colors.indicator : Theme.colors.secondary} hoverColor={Theme.colors.indicator}>
                    О продукте
                </Text16lh24px>
            </NavbarItem>

            <NavbarItem
                onClick={() => {
                    onClose()
                    onTabChange(TabEnum.partners)
                    scrollTo(ScrollToEnum.intro)
                }}>
                <Text16lh24px
                    color={selectedTab === TabEnum.partners ? Theme.colors.indicator : Theme.colors.secondary}
                    hoverColor={Theme.colors.indicator}>
                    Партнерам
                </Text16lh24px>
            </NavbarItem>
            <NavbarItem
                onClick={() => {
                    onClose()
                    onTabChange(TabEnum.support)
                    scrollTo(ScrollToEnum.intro)
                }}>
                <Text16lh24px
                    color={selectedTab === TabEnum.support ? Theme.colors.indicator : Theme.colors.secondary}
                    hoverColor={Theme.colors.indicator}>
                    Поддержка
                </Text16lh24px>
            </NavbarItem>
            <NavbarBtn>
                <DefaultBtn onClick={() => {
                    onClose()
                    onTabChange(TabEnum.main)
                    scrollTo(ScrollToEnum.cooperation)
                }} style={{backgroundColor: Theme.colors.indicator}}>
                    <Text18lh28 color={Theme.colors.secondary}>Связаться c нами</Text18lh28></DefaultBtn>
            </NavbarBtn>
        </NavbarBackground>
    )
}