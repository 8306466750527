export enum TabEnum {
    main = 1,
    partners = 2,
    support = 3,
}

export enum ScrollToEnum {
    intro = '.intro',
    partners = '.partners',
    cooperation = '.cooperation',
}

export enum Category {
    Documentation = 'Документация',
    SettingExamples = 'Примеры настройки'
}

export enum Subcategory {
    Design = 'Дизайн и проектирование',
    Download = 'Установка',
    Administration = "Администрирование",
    Malfunction = 'Поиск и устранение неисправностей'
}

export enum Company {
    Aruba = 'Aruba',
    Microsoft = 'Microsoft',
    Cisco = 'Cisco',
    Eltex = 'Eltex',
    Huawei = 'Huawei',
    VectorTechnologies = 'Вектор Технологии',
    Any = 'Any'
}

