import {Header} from "./features/header/Header";
import {ThemeProvider} from "styled-components";
import {Theme} from "./styles/Theme";
import {MainPage} from "./components/MainPage";
import { useState} from "react";
import {TabEnum} from "./enum";
import {Partners} from "./components/partners/Partners";
import {Footer} from "./features/footer/Footer";
import {Close, CooperationModal, ModalClose} from "./components/cooperation/style";
import {Cooperation} from "./components/cooperation/Cooperation";
import {Support} from "./components/support/Support";

const App = () => {
    const [currentTab, setCurrentTab] = useState(TabEnum.main)
    const [popupOpened, setPopupOpened] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [documentationOpen, setDocumentationOpen]=useState(false)

    return (
        <div className={`app ${(popupOpened || isMenuOpen) ? 'appBlocked' : ''}`}>
            <ThemeProvider theme={Theme}>
                <Header
                    isMenuOpen={isMenuOpen}
                    setIsMenuOpen={(value) => setIsMenuOpen(value)}
                    onPopupOpen={() => setPopupOpened(true)}
                    selectedTab={currentTab}
                    onTabChange={tab => {setCurrentTab(tab);setDocumentationOpen(false)}}/>
                {currentTab === TabEnum.main && <MainPage/>}
                {currentTab === TabEnum.partners && <Partners onPopupOpen={() => {setPopupOpened(true)}}/>}
                {currentTab === TabEnum.support && <Support documentationOpen={documentationOpen} setDocumentationOpen={setDocumentationOpen}/>}
                <Footer/>
                {popupOpened && <CooperationModal>
                    <ModalClose onClick={() => setPopupOpened(false)}>
                        <Close/>
                    </ModalClose>
                    <Cooperation/>
                </CooperationModal>}
            </ThemeProvider>
        </div>
    );
}

export default App;
